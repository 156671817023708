import {
  concatMap,
  distinctUntilChanged,
  filter,
  find,
  map,
  share,
  switchMap,
} from 'rxjs/operators'
import { NEVER, Subject } from 'rxjs'
import memoize from 'memoizee'
import { action, computed, observable } from 'mobx'
import { toStream } from 'utils/mobxUtil'
import { withCancellation, cancelWhen } from 'utils/rxUtil'
import { SoundId } from 'sound/SoundPlayer'
import { Cancellation } from 'utils/cancellation'
import { CallViewModel } from './CallViewModel'
import { CallOperatorState } from 'messaging/calling/constants'
import { browserHistory } from 'react-router'
import links from 'misc/links'
import { CallSourceFactory } from './CallSourceFactory'
import { PageActivity } from '@taxfyle/web-commons/lib/utils/domUtil'

export default class CallRootViewModel {
  @observable _calls = observable.set()
  connectSubject = new Subject()

  constructor(rootStore) {
    this.callStore = rootStore.callStore
    this.callingConversationVM = rootStore.callingConversationViewModel
    this.getTime = rootStore.getTime
    this.sessionStore = rootStore.sessionStore

    this.sounds = {
      incoming: rootStore.soundPlayer.getSound(SoundId.IncomingCall),
      ringing: rootStore.soundPlayer.getSound(SoundId.RingingCall),
      joined: rootStore.soundPlayer.getSound(SoundId.GotMessage),
    }

    this.adapter = {
      fetch: async (callToFetch) => {
        this.callStore.fetchCall.bind(this.callStore)
        return this.callStore.fetchCall(callToFetch.id)
      },
      getNow: this.getTime,
      decline: async (call) => this.callStore.decline(call),
      join: async (call) => this.callStore.joinCall(call),
      hangup: async (call) => this.callStore.hangup(call),
      showCallContext: (call) => {
        browserHistory.push(links.conversation(call.conversation))
      },
      acquireCallSource: CallSourceFactory(),
      canHaveOutgoingVideo: () =>
        Boolean(this.currentMember.hasPermission('CALL_ENABLE_CAMERA')),
      hangupSignal$: rootStore.pageActivity$.pipe(
        filter((a) => a === PageActivity.Exit)
      ),
      mediaSettingsDialogVM: rootStore.mediaSettingsDialogViewModel,
      mediaSettingsPorts: {
        devicePreferencesChanges$:
          rootStore.mediaSettingsStore.devicePreferencesChanges$,
        requestDevices: rootStore.mediaSettingsStore.requestDevices.bind(
          rootStore.mediaSettingsStore
        ),
        preferredVideoDevice: () =>
          rootStore.mediaSettingsStore.preferredVideoDevice,
        preferredAudioInputDevice: () =>
          rootStore.mediaSettingsStore.preferredAudioInputDevice,
      },
    }

    /**
     * Connection requests.
     *
     * @private
     */
    this.connect$ = this.connectSubject.pipe(
      map((descriptor) => {
        this.addActiveCall(descriptor.call)
        this.callViewModelFor(descriptor.call).reportConnecting()
        return descriptor
      }),
      share()
    )

    /**
     * When a call is incoming, sets the VM for it.
     */
    this.incoming$ = this.callStore.incomingCall$.pipe(
      map((call) => {
        this.addActiveCall(call)
        const callVM = this.callViewModelFor(call)
        callVM.reportIncoming()
        return call
      }),
      share()
    )

    /**
     * Whenever we want to connect to a call, it goes through here.
     * The call switcher ensures that any active call is disconnected
     * and any other incoming calls are declined.
     *
     */
    this.callSwitcher$ = this.connect$.pipe(
      concatMap(async (descriptor) => {
        // Cancel if we get another connect request.
        // The next connect request is going to wait until this handler
        // is finished due to how concatMap works.
        const cts = cancelWhen(this.connect$)
        await this.connectToCall(descriptor, cts.token).catch(
          Cancellation.ignore()
        )
        cts.dispose()
        return descriptor
      }),
      share()
    )

    /**
     * Controls the incoming call sound (ringing)
     */
    this.incomingCallSound$ = toStream(
      () => this.calls.some((c) => c.state === CallOperatorState.Incoming),
      true
    ).pipe(
      distinctUntilChanged(),
      switchMap((ringing) =>
        ringing
          ? withCancellation((ct) => {
              this.sounds.incoming.playLoop(ct)
              return NEVER
            })
          : NEVER
      ),
      share()
    )

    this.callSwitcher$.subscribe()
    this.incoming$.subscribe()
    this.incomingCallSound$.subscribe()
  }

  /**
   * Shortcut to the current member.
   */
  @computed
  get currentMember() {
    return this.sessionStore.member
  }

  @computed
  get calls() {
    return Array.from(this._calls).map(this.callViewModelFor)
  }

  @computed
  get activeCall() {
    return (
      this.calls.find(
        (c) =>
          c.state === CallOperatorState.Connecting ||
          c.state === CallOperatorState.Connected
      ) ?? null
    )
  }

  @computed
  get incomingCalls() {
    return this.calls.filter((c) => c.state === CallOperatorState.Incoming)
  }

  /**
   * Gets a Call VM for the specified Call.
   * NOTE: We cache for infinity and never actually dispose the VM
   * because:
   *  1. There are not going to be that many calls.
   *  2. Don't want to re-fetch call info for every remount of a view
   *     where the VM is needed.
   *  3. There is no one point where a VM is no longer needed.
   *     The VM is used in the chat log, the active call view and the
   *     call prompts host which shows active and incoming calls as an
   *     overlay. We _could_ use some sort of tracking of whether the VM is actively
   *     being used, but at this point it's not worth it. Relying on MobX's
   *     tracking didn't work (createTransformer) because we invoke it outside
   *     a reactive context.
   *
   */
  callViewModelFor = memoize((call) => {
    const delegates = {
      connect: this.queueConnect,
    }

    const vm = new CallViewModel(call, this.adapter, this.sounds, delegates)
    // Remove the call from the collection when it becomes inactive
    // or when it ends (both should happen).
    vm.stateChange$
      .pipe(find((s) => s === CallOperatorState.Inactive))
      .subscribe(() => this.removeActiveCall(call))

    // Fetch in the background.
    // We know that when a VM is needed that we want the
    // full call object.
    vm.fetch()
    return vm
  })

  /**
   * Queues a connection request and waits for it to go through.
   *
   * @param {CallDescriptor} descriptor
   */
  queueConnect = async (callDescriptor) => {
    this.connectSubject.next(callDescriptor)
    // Wait for the operator to emit the descriptor that we passed in.
    await this.callSwitcher$.pipe(find((d) => d === callDescriptor)).toPromise()
  }

  /**
   * Connects to the specified call.
   */
  async connectToCall({ call, connectionDescriptor }, ct) {
    const currentParticipant = call.currentUserParticipant
    if (!currentParticipant) {
      return
    }

    const vm = this.callViewModelFor(call)
    try {
      currentParticipant.markConnecting()
      const activeCall = this.activeCall
      if (activeCall && activeCall.call !== call) {
        await this.activeCall.hangup()
      }
      ct.throwIfCancelled()

      const otherIncomingCalls = this.calls.filter(
        (c) => c.state === CallOperatorState.Incoming && c.call !== call
      )
      await Promise.all(otherIncomingCalls.map((c) => c.decline()))
      ct.throwIfCancelled()

      await vm.fetch()
      await vm.connectToSource(connectionDescriptor, ct)
      currentParticipant.markConnected()
    } catch (err) {
      await vm.disconnect()
      currentParticipant.markDisconnected()
      console.error('Failed to connect to call', err)
    }
  }

  /**
   * Adds the call from to tracking set.
   *
   * @param {Call} call
   */
  @action
  addActiveCall(call) {
    this._calls.add(call)
  }

  /**
   * Removes the call from the tracking set.
   *
   * @param {Call} call
   */
  @action
  removeActiveCall(call) {
    this._calls.delete(call)
  }
}
