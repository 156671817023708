import { observable } from 'mobx'
import { Model } from 'libx'

/**
 * Document type.
 */
export default class DocumentType extends Model {
  @observable id
  @observable shortId
  @observable name

  parse(json) {
    return {
      id: json.id,
      shortId: json.short_id,
      name: json.name,
    }
  }
}
