import React from 'react'
import styles from './styles.m.sass'

export default function EmailConfirmationScreen({ children }) {
  const workspace = (window.__workspaceDomain &&
    window.__workspaceDomain.workspace) || {
    branding: { logos: {}, colors: {}, landing_page: {}, corporate: {} },
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img
            src={
              workspace.branding.logos.primary ||
              require('./img/logo-icon.png')
            }
          />
        </div>
        <div>{children}</div>
        <div className={styles.copyright}>
          <div>Copyright &copy; Tickmark, Inc {new Date().getFullYear()}</div>
        </div>
      </div>
      <div className={styles.image}>
        <img
          src="https://storage.googleapis.com/worklayer-assets/worklayer-homepage/img_login-worklayer1.svg"
          alt="A group of people around a laptop"
        />
      </div>
    </div>
  )
}
