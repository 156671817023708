import 'intl'
import 'intl/locale-data/jsonp/en'
import 'sass/base.sass'
import 'mobx-react-lite/batchingForReactDom'
import env from 'misc/env'

import { Provider } from 'mobx-react'
import { HelmetProvider } from 'react-helmet-async'
import { browserHistory } from 'react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './shell/AppRouter'
import { AppContainer as HotContainer } from 'react-hot-loader'
import rootStore from './store'
import LogRocket from 'logrocket'
import { desaturate, lighten, darken, saturate, parseToRgb } from 'polished'
import setupLogRocketReact from 'logrocket-react'

import {
  getFeatureToggleClient,
  initializeToggleClient,
} from 'misc/featureToggles'
import Dictionary from '@taxfyle/web-commons/lib/utils/dictionary'

const featureToggleClient = getFeatureToggleClient()

/**
 * initialize feature toggle client and
 * then render the app.
 */
initializeToggleClient(featureToggleClient)
  .then(() => undefined)
  .catch((err) =>
    console.error('Error initializing feature toggle client', err)
  )
  .finally(() => {
    rootStore.onTogglesInitialized()
    initApp()
  })

function setBrandingVariables(workspace) {
  const primary =
    workspace.branding &&
    workspace.branding.colors &&
    workspace.branding.colors.primary

  const themePrimary = primary || null
  const primaryDarken = saturate(0.3, darken(0.13, primary))
  const primaryLighten = desaturate(0.1, lighten(0.3, primary))
  const primarySemiLight = desaturate(0.1, lighten(0.1, primary))

  function toRgbStr(color) {
    const asRgb = parseToRgb(color)
    return `${asRgb.red}, ${asRgb.green}, ${asRgb.blue}`
  }

  document.body.style.setProperty('--theme-base', themePrimary)
  document.body.style.setProperty('--theme-darken', primaryDarken)
  document.body.style.setProperty('--theme-lighten', primaryLighten)
  document.body.style.setProperty('--theme-semi-light', primarySemiLight)
  document.body.style.setProperty('--theme-base-rgb', toRgbStr(themePrimary))
  document.body.style.setProperty('--theme-darken-rgb', toRgbStr(primaryDarken))
  document.body.style.setProperty(
    '--theme-lighten-rgb',
    toRgbStr(primaryLighten)
  )
}

function initApp() {
  if (
    window &&
    window.__workspaceDomain &&
    window.__workspaceDomain.workspace
  ) {
    setBrandingVariables(window.__workspaceDomain.workspace)
    rootStore.setHydratedWorkspaceDomain(window.__workspaceDomain)
  }

  rootStore.sessionStore.onWorkspaceSwitched(() => {
    browserHistory.push('/')
  })

  rootStore.sessionStore.onWorkspaceSelected((workspace) => {
    Dictionary.setTranslations(
      rootStore.translationStore.dictonaryForWorkspace(workspace.id)
    )
    setBrandingVariables(workspace)
  })

  if (env.LOGROCKET_KEY) {
    LogRocket.init(env.LOGROCKET_KEY, {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf('/messages') !== -1) {
            request.body = null
          }

          // Block requests from CJ Affiliate program from reaching LogRocket.
          if (request.url.toLowerCase().includes('emjcd.com')) {
            return null
          }

          return request
        },
        responseSanitizer: (response) => {
          if (response.url.toLowerCase().indexOf('/messages') !== -1) {
            response.body = null
          }
          return response
        },
      },
    })
    setupLogRocketReact(LogRocket)
  }

  const render = (Component) => {
    ReactDOM.render(
      <HelmetProvider>
        <Provider rootStore={rootStore}>
          <HotContainer>
            <Component />
          </HotContainer>
        </Provider>
      </HelmetProvider>,
      document.getElementById('app-container')
    )
  }

  if ('ActiveXObject' in window) {
    document.body.classList.add('browser-ie')
  }

  render(AppRouter)

  if (module.hot) {
    module.hot.accept('./shell/AppRouter', () => render(AppRouter))
  }
}
