import { Store } from 'libx'
import DataLoader from 'dataloader'
import DocumentType from 'domain/DocumentType'

export default class DocumentTypeStore extends Store {
  documentTypes = this.collection({
    model: DocumentType,
  })

  constructor({ rootStore }) {
    super({ rootStore })

    this.api = rootStore.api
    this.shortIdLoader = new DataLoader(this._fetchManyByShortId.bind(this))
  }

  fetchByShortId(shortId) {
    return this.shortIdLoader.load(shortId)
  }

  _fetchManyByShortId(shortIds) {
    return this.api.documentTypes
      .getMany({
        ids: shortIds,
      })
      .then(this.documentTypes.set)
  }
}
