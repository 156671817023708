import { Model, referenceMany } from 'libx'
import { computed, observable, action } from 'mobx'
import { markdownToHtml } from 'utils/markdown'
import { maybeParseDate } from 'utils/dateUtil'

/**
 * A requested supporting item.
 */
export class RequestedSupportingItem extends Model {
  @observable id = ''
  @observable name = ''
  @observable heading = ''
  @observable shortDescription = ''
  @observable longDescription = ''
  @observable imageUrl = ''
  @observable previewUrl = ''
  @observable required = false

  @observable input

  constructor(attrs, opts) {
    super(attrs, opts)
    this.section = opts.section
  }

  @computed
  get completed() {
    return Boolean(this.input?.completed)
  }

  @computed
  get longDescriptionHtml() {
    if (this.longDescription) {
      return markdownToHtml(this.longDescription)
    }

    return ''
  }

  parse(json) {
    return {
      id: json.id,
      name: json.name,
      heading: json.heading,
      shortDescription: json.short_description,
      longDescription: json.long_description,
      imageUrl: json.image_url?.value,
      previewUrl: json.preview_url?.value,
      required: json.required,
      dateModified: maybeParseDate(json.update_time),
      input: parseSupportingItemInput(json.input, {
        rootStore: this.rootStore,
      }),
    }
  }

  static fromJSON(json, opts) {
    return new RequestedSupportingItem(json, {
      ...opts,
      parse: true,
      stripUndefined: true,
    })
  }
}
/**
 * Parses a supporting item input.
 */
function parseSupportingItemInput(json, opts) {
  if (!json) {
    return json
  }

  if (json.type === 'DOCUMENT') {
    return new DocumentSupportingItemInput(json, {
      ...opts,
      parse: true,
      stripUndefined: true,
    })
  }

  return null
}

/**
 * Supporting item input for a document type.
 */
export class DocumentSupportingItemInput extends Model {
  @observable documentTypeShortId = ''
  @observable documentIds = []

  @computed
  get documents() {
    return referenceMany(
      this.rootStore.documentStore.documents,
      this.documentIds,
      'id'
    ).filter((x) => !x.archived)
  }

  @computed
  get completed() {
    return this.documentIds.length > 0
  }

  parse(json) {
    return {
      documentTypeShortId: json.document_type_short_id,
      documentIds: json.document_ids,
    }
  }

  @action.bound
  addDocument(docId) {
    if (this.documentIds.includes(docId)) {
      return
    }
    this.documentIds.push(docId)
  }

  @action.bound
  removeDocument(docId) {
    this.documentIds.remove(docId)
  }
}
