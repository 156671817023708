const link = {
  logout: () => '/logout',
  login: () => '/login',
  projects: () => '/',
  projectDetails: (projectId) => `/project/${projectId}`,
  projectEditor: (projectId) => `/project/${projectId}/edit`,
  allDocuments: () => '/documents',
  profile: () => '/profile',
  linkedAccounts: () => '/linked-accounts',
  coupons: () => '/coupons',
  referAFriend: () => '/refer',
  newProject: () => '/new',
  billing: () => '/billing',
  teams: () => '/teams',
  notificationPreferences: () => '/notifications/preferences',
  conversation: (conversation) => {
    if (conversation.meta.type === 'Job') {
      const jobId = conversation.meta.id
      return link.projectDetails(jobId)
    }
    console.warn('Tried to navigate to a conversation not in a Job somehow')
    return link.welcome()
  },
}

export default link
