import axios from 'axios'
import decodeJwt from 'jwt-decode'
import { sessionStorage } from 'misc/storage'
import { browserHistory } from 'react-router'

const sessionRefreshTokenKey = '__session_refresh_token'
export const sessionReturnOptionsKey = '__session_return_options'

export default class MagicLinkClient {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL: baseURL,
    })

    this.token = null
  }

  /** Returns the token upon successful retrieval. */
  async getToken() {
    // let's get the session from the session storage in case the user refresh the page
    const refreshToken = sessionStorage.getItem(sessionRefreshTokenKey)

    if (this.token) {
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const decoded = decodeJwt(this.token)

      if (decoded.exp - 30 > currentTimestamp) {
        return this.token
      }
    }

    if (!refreshToken) {
      return null
    }

    const res = await this.api
      .post('/v3/auth/magic-link/token/refresh', {
        refresh_token: refreshToken,
      })
      .then((res) => res.data)
      .catch((err) => console.error('Error fetching magic link token', err))

    // let's keep the token in memory
    this.token = res ? res.access_token : null

    return this.token
  }

  /**
   * Returns the session from the successful retrieval of the token.
   *
   * @returns {Object}
   */
  async fetchSessionInfo() {
    const token = await this.getToken()
    const decoded = decodeJwt(token)

    return { token, authId: decoded.sub, claims: [] }
  }

  /**
   * Redirects to the expiration page.
   *
   * @returns {Object}
   */
  logout() {
    sessionStorage.removeItem(sessionRefreshTokenKey)
    return window.location.replace('/__session/expired')
  }

  /**
   * Handles redirect after exchanging the authorization code.
   *
   * @returns {Object}
   */
  async handleRedirect() {
    const isRoot = window.location.pathname === '/'
    const session = window.__magicLinkSession

    if (isRoot && session) {
      const res = await this.api
        .post('/v3/auth/magic-link/code/exchange', {
          session_id: session.id,
          authorization_code: session.authorizationCode,
        })
        .then((res) => res.data)
        .catch((err) => {
          console.error('Error fetching magic link refresh token', err)
          this.logout()
        })

      this.token = res.access_token

      sessionStorage.setItem(sessionRefreshTokenKey, res.refresh_token)
      sessionStorage.setItem(
        sessionReturnOptionsKey,
        JSON.stringify({ returnOptions: session.returnOptions })
      )

      browserHistory.replace(session.targetUrl)
    }
  }
}

/**
 * Creates a MagicLinkClient object when the value of window.__magicLinkSession
 * is defined or the refresh token is set in the session storage.
 *
 * @returns {MagicLinkClient | null}
 */
export function createMagicLinkClient(baseUrl) {
  const magicLinkSession = window.__magicLinkSession
  const session = sessionStorage.getItem(sessionRefreshTokenKey)

  return magicLinkSession || session ? new MagicLinkClient(baseUrl) : null
}
