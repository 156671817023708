import React from 'react'

export default function intercomUpdate(attrs) {
  return function decorator(Target) {
    return class Wrapped extends React.Component {
      componentDidMount() {
        if (
          isB2B2C(this.props.rootStore) &&
          this.props.rootStore.intercomStore.booted
        ) {
          this.props.rootStore.intercomStore.shutdown()
        } else {
          this.props.rootStore.intercomStore.update(attrs)
        }
      }

      render() {
        return <Target {...this.props} />
      }
    }
  }
}

const isB2B2C = (rootStore) => {
  const workspace =
    rootStore.sessionStore.workspace ??
    rootStore.workspaceStore.workspaces.find((x) => x.isDomainMatchedWorkspace)
  return workspace?.isB2B2C
}
