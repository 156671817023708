import { observable, action, computed } from 'mobx'
import { task } from 'mobx-task'
import defer from 'promise-defer'
import { Store } from 'libx'

export default class ConfirmScheduleDialogStore extends Store {
  @observable
  showing = false

  @observable
  success = false

  @computed
  get consultation() {
    return this.rootStore.scheduleStore.consultation
  }

  @computed
  get consultationLength() {
    return this.consultation?.requestedLength || 60
  }

  @action
  async show() {
    if (this.deferredRequest) {
      this.deferredRequest.resolve(false)
    }

    this.showing = true
    this.deferredRequest = defer()
    this.shouldShowPhoneNumber =
      !this.consultation?.availability &&
      this.rootStore.scheduleStore.isPhoneCall

    return this.deferredRequest.promise
  }

  @action
  close() {
    this._resolve()
    this.showing = false
    this.success = false
  }

  @task.resolved
  async submit() {
    await this.rootStore.scheduleStore.setConsultationAvailability().then(
      action(() => {
        this.showing = false
        this.success = !this.shouldShowPhoneNumber
      })
    )

    if (
      this.rootStore.projectDetailsStore?.project.status === 'INFO_GATHERING'
    ) {
      await this.rootStore.jobStore.completeInfoGathering(
        this.rootStore.projectDetailsStore?.project.id
      )
    }

    if (this.shouldShowPhoneNumber) {
      await this.rootStore.confirmPhoneDialogStore.show().then(
        action(() => {
          this.success = true
        })
      )
    }
    this._resolve()
  }

  _resolve = () => {
    if (!this.deferredRequest) return
    this.deferredRequest.resolve(true)
  }
}
